<template>
  <section class="policy-details">
    <s-container v-if="policy">
      <div class="policy-details__header">
        <button class="back" @click.prevent="back">
          <img :src="backIcon" alt="Voltar" />
        </button>
        <div>
          <p v-if="policy.status === 'READ'" class="policy-accepted">
            {{
              $t('POLICY_DETAILS.ACCEPTED', {
                date: formattedDate(policy.completedAt),
              })
            }}
          </p>
        </div>
        <div class="policy-title">
          <h2>{{ policy.title }}</h2>
          <p>{{ policy.description }}</p>
        </div>
      </div>
      <div class="policy-details__content">
        <div v-if="policyFileSrc" class="file">
          <vue-pdf-embed :source="policyFileSrc" width="800" />
          <a
            v-if="policy.status === 'READ'"
            :href="policyFileSrc"
            :download="policy.title"
            class="policy-download"
          >
            <img :src="downloadIcon" alt="download" />
          </a>
        </div>
        <div v-if="policy.status !== 'READ'" class="policy-footer">
          <div class="agreed">
            <input id="agreed" v-model="agreed" type="radio" @click="onClick" />
            <label for="agreed">{{ $t('POLICY_DETAILS.AGREED') }}</label>
          </div>
          <div class="submit">
            <s-button :disabled="agreed" @click.prevent="submit">
              {{ $t('POLICY_DETAILS.AGREED_BUTTON') }}
            </s-button>
          </div>
        </div>
        <div v-if="errorMessage" class="error-message">
          <md-snackbar
            class="bg-error top"
            md-position="center"
            :md-active.sync="showSnackbar"
            md-persistent
          >
            <span>{{ errorMessage }}</span>
          </md-snackbar>
        </div>
      </div>
    </s-container>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import router from '@/plugins/router'
import SContainer from '@/components/ui/s-container'
import backIcon from '@/assets/images/issues/back_eco_icon.svg'
import downloadIcon from '@/assets/images/issues/download_icon.svg'
import SButton from '@/components/ui/s-button'

export default {
  name: 'PolicyDetails',

  components: {
    SContainer,
    VuePdfEmbed,
    SButton,
  },

  data() {
    return {
      backIcon,
      downloadIcon,
      policy: null,
      policyFileSrc: null,
      agreed: true,
      errorMessage: '',
      showSnackbar: false,
    }
  },

  computed: {
    ...mapState(['companyId', 'locale']),

    validLocale() {
      return this.locale === 'pt-BR' ? 'pt' : this.locale
    },

    policyId() {
      const { policyId } = router.currentRoute.params
      return policyId
    },

    disabled() {
      return !this.agreed
    },
  },

  async mounted() {
    try {
      this.policy = await this.getDocument(this.policyId)

      const getFileUrlResponse = await this.getDocumentFileUrl({
        companyId: this.companyId,
        filekey: this.policy.filekey,
      })
      this.policyFileSrc = getFileUrlResponse.url
    } catch (error) {
      this.errorMessage = this.$t('POLICY_DETAILS.DOCUMENT_NOT_FIND')
      this.showSnackbar = true
    }
  },

  methods: {
    ...mapActions(['getDocument', 'getDocumentFileUrl', 'documentRead']),

    formattedDate(date) {
      const options = {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      }

      const dateTimeObject = new Date(date)

      const formattedDateTime = dateTimeObject.toLocaleDateString(
        this.validLocale,
        options,
      )

      return formattedDateTime
    },

    back() {
      this.$router.go(-1)
    },

    onClick() {
      this.agreed = !this.agreed
    },

    async submit() {
      try {
        await this.documentRead(this.policyId)
        this.back()
      } catch (error) {
        this.errorMessage = this.$t('POLICY_DETAILS.READ_ERROR')
        this.showSnackbar = true
      }
    },
  },
}
</script>

<style scoped>
.back {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
}
.policy-details__header .policy-title {
  margin: 0 0 10px 30px;
  padding-top: 5px;
}

.policy-details__content {
  display: flex;
  flex-direction: column;
}
.file {
  position: relative;
  height: 380px;
  overflow-y: scroll;
  margin: 20px 30px 30px;
  box-shadow: 2px 2px #888888;
  background: white;
  border: 1px solid;
}
.policy-accepted {
  display: inline-block;
  width: auto;
  border-radius: 2px;
  background-color: var(--color-green-default);
  color: white;
  padding: 8px 12px;
  margin: 0 0 30px 30px;
}
.policy-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.agreed {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.submit {
  width: 30px;
  padding: 10px 0 0;
  justify-content: center;
  display: flex;
  align-items: center;
  align-self: center;
}
.policy-download {
  position: absolute;
  right: 20px;
  top: 24px;
}

.policy-download img {
  width: 20px;
}

@media (max-width: 480px) {
  .policy-download {
    left: 8px;
    top: 9px;
    right: auto;
  }
}
</style>
